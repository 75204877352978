// `pointer-events: none` isn't supported by IE11, setting `cursor: default` just makes it appear less like a link
.pe-none {
  cursor: default;
}

.text-break {
  word-wrap: break-word !important;
}

.user-select-none {
  -ms-user-select: none !important;
}

.user-select-auto {
  -ms-user-select: auto !important;
}

// Text opacity
.text-opacity-75 {
  opacity: 0.75;
}

.text-opacity-50 {
  opacity: 0.5;
}

.text-opacity-25 {
  opacity: 0.25;
}

// Text colors
.text-primary {
  color: rgb(13, 110, 253);
}

.text-secondary {
  color: rgb(108, 117, 125);
}

.text-success {
  color: rgb(25, 135, 84);
}

.text-danger {
  color: rgb(220, 53, 69);
}

.text-warning {
  color: rgb(255, 193, 7);
}

.text-info {
  color: rgb(13, 202, 240);
}

.text-light {
  color: rgb(248, 249, 250);
}

.text-dark {
  color: rgb(33, 37, 41);
}

.text-body {
  color: rgb(33, 37, 41);
}

.text-muted {
  color: rgb(108, 117, 125);
}

.text-white {
  color: rgb(255, 255, 255);
}

// IE11 doesn't support :not(:focus-within) so the rules defined in Bootstrap 5 are repeated here
.visually-hidden,
.visually-hidden-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

// horizontal stack gap
.hstack.gap-1 > * {
  margin-right: 0.25rem;
}

.hstack.gap-1 > *:last-child {
  margin-right: 0;
}

.hstack.gap-2 > * {
  margin-right: 0.5rem;
}

.hstack.gap-2 > *:last-child {
  margin-right: 0;
}

.hstack.gap-3 > * {
  margin-right: 1rem;
}

.hstack.gap-3 > *:last-child {
  margin-right: 0;
}

.hstack.gap-4 > * {
  margin-right: 1.5rem;
}

.hstack.gap-4 > *:last-child {
  margin-right: 0;
}

.hstack.gap-5 > * {
  margin-right: 3rem;
}

.hstack.gap-5 > *:last-child {
  margin-right: 0;
}

// vertical stack gap
.vstack.gap-1 > * {
  margin-bottom: 0.25rem;
}

.vstack.gap-1 > *:last-child {
  margin-bottom: 0;
}

.vstack.gap-2 > * {
  margin-bottom: 0.5rem;
}

.vstack.gap-2 > *:last-child {
  margin-bottom: 0;
}

.vstack.gap-3 > * {
  margin-bottom: 1rem;
}

.vstack.gap-3 > *:last-child {
  margin-bottom: 0;
}

.vstack.gap-4 > * {
  margin-bottom: 1.5rem;
}

.vstack.gap-4 > *:last-child {
  margin-bottom: 0;
}

.vstack.gap-5 > * {
  margin-bottom: 3rem;
}

.vstack.gap-5 > *:last-child {
  margin-bottom: 0;
}

// vertical-rule
.vr {
  border-right: 1px solid rgb(33, 37, 41);
}

// flex
// fix for: https://github.com/coliff/bootstrap-ie11/issues/84
.justify-content-evenly {
  justify-content: space-around !important;
}
