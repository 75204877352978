.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

// IE11 doesn't support input=color.
// This changes the input so its not too narrow to allow a hex input (e.g. #FFFFFF)
.form-control-color {
  width: 5rem;
}

.form-control:disabled {
  -ms-user-select: none !important;
}
